<template>
<div>
  <nav-box></nav-box>
  <div class="w" style="margin-top: 20px">
    <p class="welcome">尊敬的供应商您好，欢迎您注册沃莱迪招采平台！</p>
    <p class="welcome">请按照下面步骤完成注册操作。</p>
    <p style="margin-top: 30px">第一步：打开沃莱迪官网首页-供应商登录框-点击注册按钮</p>
    <div>
      <img src="@/assets/images/help3.png" alt="">
    </div>

    <p style="margin-top: 30px">第二步：阅读反商业贿赂承诺书-勾选同意按钮-点击继续注册</p>
    <div>
      <img src="@/assets/images/help4.png" alt="">
    </div>

    <div>
      <img src="@/assets/images/help7.png" alt="">
    </div>

    <p style="margin-top: 30px">第三步：进入注册第一步，按照内容填写信息，点击下一步</p>
    <p style="color: red">注意：登录手机号是您的登录账号，必须填写真实有效的手机账号，否则无法进
      行下面操作。</p>
    <div>
      <img src="@/assets/images/help1.png" alt="">
    </div>

    <p style="margin-top: 30px">第四步：进入注册第二步，填写信息，点击下一步</p>
    <div>
      <img src="@/assets/images/help5.png" alt="">
    </div>
    <p style="margin-top: 30px">恭喜您，已完成沃莱迪供应商的的信息填写，请等待审核。</p>
    <div>
      <img src="@/assets/images/help6.png" alt="">
    </div>
  </div>
  <div class="w out" @click="getOut">返回</div>
  <qq-footer></qq-footer>
</div>
</template>

<script>
import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'
export default {
  components: { QqFooter, NavBox },
  data () {
    return {

    }
  },
  methods: {
    getOut () {
      this.$router.push('/index')
    }
  }
}
</script>

<style scoped>
.w {
  width: 1200px;
  margin: 0 auto;
}
.out {
  text-align: center;
  margin: 20px auto;
  cursor: pointer;
}
p {
  font-size: 14px;
  color: #666666;
}
.welcome {
  font-size: 16px;
  color: #666666;
}
</style>
